<template>
  <div>
    <div v-if="lead" class="lead">
      <div class="lead__header">
        <div class="lead__header-title">
          <h2 class="section-title lead__title">{{ lead.title }}</h2>
          <p class="lead__title-subtitle">Дата создания: {{ lead.date }}</p>
          <p class="lead__title-subtitle" v-if="lead.date_update">Последние изменения: {{ lead.date_update }}</p>
          <p class="lead__title-subtitle" v-if="lead.address">Адрес: {{ lead.address }}</p>
        </div>
        <div class="lead__header-share">
            <share-social :leadId="id" :leadTitle="lead.title"/>
        </div>
      </div>

      <table class="table">
        <tr class="questions">
          <td v-for='(list, index) in leadItems.questions' :key="index">{{ list.val }}</td>
        </tr>
        <tr class="answers">
          <td v-for='(list, index) in leadItems.answers' :key="index">{{Array.isArray(list) ? list.join(', ') : list }}</td>
        </tr>
        <tr class="prices">
          <td v-for='(list, index) in leadItems.prices' :key="index">{{Array.isArray(list) ? list.join(', ') : list}}</td>
        </tr>
      </table>

      <div class="lead__btns" v-if="isAdmin">
        <button class="lead__btn lead__btn-edit" @click="edit(id)">
          изменить сделку
        </button>
        <button class="lead__btn lead__btn-delete" @click="openedModal = true">
          удалить сделку
        </button>
      </div>

      <modal-delete-lead
          v-if="openedModal"
          :leadName="lead.title"
          :leadId="id"
          @close="openedModal = false"
      />
    </div>

    <div v-else class="lead">
      <h2 class="section-title">Сделки с id = {{ id }} не существует</h2>
    </div>
  </div>
</template>

<script>
  import ModalDeleteLead from "../components/ModalDeleteLead";
  import ShareSocial from "../components/ShareSocial";

  export default {
    components: {ModalDeleteLead, ShareSocial},
    props: ['id'],
    data() {
      return {
        openedModal: false,
      }
    },
    beforeMount() {
      this.$store.dispatch('leads/loadOneLead', this.$props.id)
    },
    computed: {
      lead() {
        return this.$store.getters['leads/oneLead']
      },
      leadItems() {
        return this.lead.items
      },
      isAdmin() {
        return this.$store.getters['auth/isAdmin']
      },
    },
    methods: {
      edit(id) {
        this.$router.push(`/quiz/${id}`)
      },
    }
  }
</script>

<style lang="scss">
  @import '../style/colors.scss';
  .lead {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      &-title {
        margin-bottom: 5px;
      }
    }
    &__btns {
      margin-top: 40px;
    }
    &__btn {
      width: auto;
      height: auto;
      background: transparent;
      padding: 10px 15px;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      transition: .3s;
      &-edit {
        color: $success;
        border: 1px solid $success;
        margin-right: 15px;
        &:hover {
          background: lighten($success, 48%);
        }
      }
      &-delete {
        color: $danger;
        border: 1px solid $danger;
        &:hover {
          background: lighten($danger, 43%);
        }
      }
    }
  }
  .table {
    text-align: center;
    border: 2px solid #fff;
    border-collapse: collapse;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    & .questions {
      & td {
        background: #f8f8f8;
        font-size: 24px;
        font-weight: 500;
        padding: 10px 20px;
        border: 1px solid #999;
      }
    }
    & td {
      font-size: 20px;
      font-weight: 400;
      padding: 10px;
      border: 1px solid #999;
    }
  }

</style>
