<template>
    <div>
        <div class="modal">
            <div class="modal__block">
                <a class="modal__block-close" href="#" @click.prevent="$emit('close')">x</a>
                <h3 class="modal__block-title">Вы точно хотите удалить сделку <br>— {{ leadName }}</h3>
                <div class="modal__block-btns">
                    <button class="btn success modal__block-btn-success" @click="deleteLead()">Удалить</button>
                    <button class="btn danger modal__block-btn-danger" @click="$emit('close')">Отмена</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['leadName', 'leadId'],
        computed: {
            id() {
                return this.leadId
            }
        },
        methods: {
            deleteLead() {
                this.$store.dispatch('leads/removeLead', this.id)
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss">
    .modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.8);
        z-index: 1000;
        &__block {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            background-color: #fff;
            padding: 30px;
            &-close {
                display: inline-block;
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 20px;
            }
            &-title {
                font-size: 22px;
                font-weight: 500;
                margin-bottom: 20px;
            }
            &-btn {
                &-success {
                    margin-right: 20px;
                }
            }
        }
    }
</style>
